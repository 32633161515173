import React from 'react'
import PropTypes from 'prop-types'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'

import { Translation } from '../../../../helpers/Translation'

import Select from '../../../../components/Select'

import ColumnSwitch from '../../Common/ColumnSwitch'
import SectionTop from '../../Common/SectionTop'

import { ReactComponent as ColoursSvg } from '../../../../svg/colours.svg'

import './index.scss'

const Colours = ({ handlePropertyId, orderFormatActions, disableAll }) => {
  const colorSpaceValues = {
    sRGB: 'sRGB (Web)',
    adobeRGB1998: 'Adobe RGB (1998)',
    CMYK: 'CMYK (Print)',
  }

  return (
    <section className={`new-order-section section-colours ${orderFormatActions?.colours?.value ? 'active' : ''}`}>
      <Accordion expanded={!!orderFormatActions?.colours?.value} className="accordion">
        <AccordionSummary className="accordion-summary">
          <SectionTop
            showContent={!!orderFormatActions?.colours?.value}
            onChange={(id, value) => handlePropertyId(id, value)}
            id="colours"
            icon={<ColoursSvg />}
            defaultChecked={orderFormatActions?.colours?.value !== null}
            labelText={orderFormatActions?.colours?.label}
            modalText={Translation.colours_modal_text}
            badgeText={orderFormatActions?.colours?.price}
            disabled={disableAll}
          />
        </AccordionSummary>
        <AccordionDetails className="accordion-details">
          <div className="section-content">
            <div className="section-content-columns">
              <div className="section-content-column">
                <ColumnSwitch
                  id="colours_optimisation"
                  labelText={orderFormatActions?.colours_optimisation?.label}
                  modalText={Translation.colours_color_optimisation_modal}
                  badgeText={orderFormatActions?.colours_optimisation?.price}
                  defaultChecked={orderFormatActions?.colours_optimisation?.value !== null}
                  onChange={(id, value) => { handlePropertyId(id, value) }}
                  disabled={disableAll}
                />
              </div>
              <div className="section-content-column">
                <ColumnSwitch
                  id="colours_colouration"
                  labelText={orderFormatActions?.colours_colouration?.label}
                  modalText={Translation.colours_colouration_modal}
                  badgeText={orderFormatActions?.colours_colouration?.price}
                  defaultChecked={orderFormatActions?.colours_colouration?.value !== null}
                  onChange={(id, value) => { handlePropertyId(id, value) }}
                  disabled={disableAll}
                />
              </div>
              <div className="section-content-column">
                <ColumnSwitch
                  id="colours_matching"
                  labelText={orderFormatActions?.colours_matching?.label}
                  modalText={Translation.colours_colour_matching_modal}
                  badgeText={orderFormatActions?.colours_matching?.price}
                  defaultChecked={orderFormatActions?.colours_matching?.value !== null}
                  onChange={(id, value) => { handlePropertyId(id, value) }}
                  disabled={disableAll}
                />
              </div>
              <div className="section-content-column">
                <ColumnSwitch
                  id="colour_space"
                  labelText={orderFormatActions?.colour_space?.label}
                  modalText={Translation.colours_colour_space}
                  badgeText={orderFormatActions?.colour_space?.price}
                  defaultChecked={orderFormatActions?.colour_space?.value !== null}
                  onChange={(id, value) => { handlePropertyId(id, value) }}
                  disabled={disableAll}
                />
                {
                  orderFormatActions.colour_space.value && (
                    <div className="column-content">
                      <div className="column-input">
                        <Select
                          isEmptyOption={false}
                          values={colorSpaceValues}
                          value={orderFormatActions.colour_space_type.value}
                          onChange={(e) => handlePropertyId('colour_space_type', e.target.value)}
                          itemClass="new-order-select-item"
                          disabled={disableAll}
                        />
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </section>
  )
}

Colours.propTypes = {
  handlePropertyId: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  orderFormatActions: PropTypes.objectOf(PropTypes.any),
  disableAll: PropTypes.bool,
}

Colours.defaultProps = {
  orderFormatActions: {},
  disableAll: false,
}

export default Colours
