import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { Tooltip } from '@mui/material'

import {
  DESKTOP_PX_TO_REM, DESKTOP_TABLE_WIDTH, FTP_MODAL_TYPE, FTP_STATUS, TABLES, PX_TO_REM,
} from '../../../helpers/Constants'
import { getPerPage, savePerPage } from '../../../helpers/Utils'
import { Translation, ftpGuideLinks } from '../../../helpers/Translation'

import Button from '../../../components/Button'
import Typography from '../../../components/Typography'
import Link from '../../../components/Link'
import Table from '../../../components/Table'

import ResetPasswordModal from '../ResetPasswordModal'

import { ReactComponent as FilezillaIcon } from '../../../svg/filezilla.svg'
import { ReactComponent as ResetPasswordIcon } from '../../../svg/reset_ftp_password.svg'
import { ReactComponent as ShowPasswordIcon } from '../../../svg/show_password.svg'
import TableTopInfo from '../../../layouts/Common/TableTopInfo'

import './index.scss'

const TableView = () => {
  const isTablet = useStoreState((state) => state.layout.isTablet)
  const ftpInfo = useStoreState((state) => state.ftp.ftpInfo)
  const user = useStoreState((state) => state.user.user)
  const getFtpFolders = useStoreActions((state) => state.ftp.getFtpFolders)
  const folders = useStoreState((state) => state.ftp.folders)
  const folderCount = useStoreState((state) => state.ftp.folderCount)
  const isPasswordShown = useStoreState((state) => state.ftp.isPasswordShown)

  const [rowsPerPage, setRowsPerPage] = useState(getPerPage(TABLES.FTP_FOLDERS))
  const [page, setPage] = useState(1)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalType, setModalType] = useState(FTP_MODAL_TYPE.question)
  const [password, setPassword] = useState(ftpInfo.ftp_password_generated)

  const getTableData = () => {
    getFtpFolders({ page, limit: rowsPerPage })
  }

  useEffect(() => {
    getTableData()
    savePerPage(TABLES.FTP_FOLDERS, rowsPerPage)
  }, [page, rowsPerPage])

  useEffect(() => {
    setPassword(ftpInfo.ftp_password_generated)
  }, [ftpInfo.ftp_password_generated])

  const onPasswordClick = (type) => {
    setModalType(type)
    setTimeout(() => {
      setIsModalOpen(true)
    }, 100)
  }

  const infoTableRows = [
    {
      id: 1,
      title: `${Translation.protocol}:`,
      value: Translation.secure_file_transfer_protocol,
    },
    {
      id: 2,
      title: `${Translation.host}:`,
      value: Translation.ftp_doopic_com,
    },
    {
      id: 3,
      title: `${Translation.username}:`,
      value: user?.ftp_username,
    },
    {
      id: 4,
      title: `${Translation.password}:`,
      value: '*******',
      otherContent: (
        <Button
          size="xs"
          onClick={() => onPasswordClick(
            (ftpInfo.user_ftp_request_status === FTP_STATUS.requestGranted
                || ftpInfo.user_ftp_request_status === FTP_STATUS.requestGrantedNewUser) && !isPasswordShown
              ? FTP_MODAL_TYPE.show : FTP_MODAL_TYPE.question,
          )}
          label={
            (ftpInfo.user_ftp_request_status === FTP_STATUS.requestGranted
                || ftpInfo.user_ftp_request_status === FTP_STATUS.requestGrantedNewUser) && !isPasswordShown
              ? <ShowPasswordIcon />
              : (
                <Tooltip
                  arrow
                  className="info-tooltip"
                  PopperProps={{
                    disablePortal: true,
                  }}
                  disableFocusListener
                  disableTouchListener
                  title={Translation.reset_password}
                >
                  <ResetPasswordIcon />
                </Tooltip>
              )
          }
        />
      ),
    },
  ]

  const colWidth = [338, 301, 301, 201]
  // last column width is calculated as the rest of available table width
  colWidth.push(DESKTOP_TABLE_WIDTH - colWidth.reduce((a, b) => a + b))

  const tableColumns = [
    {
      id: 'template_name',
      label: Translation.workflow,
      sortable: false,
      width: colWidth[0] / DESKTOP_PX_TO_REM,
    },
    {
      id: 'input',
      label: Translation.input_folder,
      sortable: false,
      width: colWidth[1] / DESKTOP_PX_TO_REM,
      render: 'jsx',
    },
    {
      id: 'output',
      label: Translation.output_folder,
      sortable: false,
      width: colWidth[2] / DESKTOP_PX_TO_REM,
    },
    {
      id: 'formats',
      label: Translation.formats,
      sortable: false,
      width: colWidth[3] / DESKTOP_PX_TO_REM,
      truncateLength: 15,
    },
    {
      id: 'turnaround',
      label: Translation.time,
      sortable: false,
      width: colWidth[4] / DESKTOP_PX_TO_REM,
    },
  ]

  const transformData = () => {
    const tempData = []

    folders.forEach((folder) => {
      tempData.push({
        template_name: (
          <div className="word-break">
            <Typography
              variant="xs-narrow"
              label={folder.template_name}
              style={{ cursor: 'default' }}
            />
          </div>),
        input: (
          <div className="word-break">
            <Typography
              variant="body1"
              label={folder.input}
              fontSize={PX_TO_REM['10']}
              lineHeight={PX_TO_REM['13']}
            />
          </div>
        ),
        output: (
          <div className="word-break">
            <Typography
              variant="body1"
              label={folder.output}
              fontSize={PX_TO_REM['10']}
              lineHeight={PX_TO_REM['13']}
            />
          </div>
        ),
        formats: folder.formats,
        turnaround: folder.turnaround,
      })
    })

    return tempData
  }

  const onRowsPerPageChange = (value) => {
    setRowsPerPage(value)
  }

  const onPageChange = (value) => {
    setPage(value)
  }

  if (folders === null) return null

  return (
    <div className="ftp-page">
      <div className="top-section">
        <div className="column">
          <div className="column-title-container">
            <Typography lineHeight={PX_TO_REM['16']} variant="s" font="semibold" label={Translation.ftp_connection_settings} />
          </div>
          <ul className="connection-info-table">
            {infoTableRows.map((row) => (
              <li key={row.id}>
                <div className="info-table-row-title">
                  <Typography variant="xs" font="medium" lineHeight={PX_TO_REM['21']} label={row.title} />
                </div>
                <div className="info-table-row-content">
                  <Typography variant="xs" label={row.value} lineHeight={PX_TO_REM['21']} inlineBlock />
                  {row.otherContent && row.otherContent}
                </div>
              </li>
            ))}
          </ul>
        </div>

        {!isTablet && (
          <div className="column">
            <div className="column-title-container">
              <Typography variant="s" font="semibold" label={Translation.ftp_client} />
            </div>
            <div className="column-subtitle-container">
              <Typography lineHeight={PX_TO_REM['21']} variant="xs" label={Translation.we_recommend_filezilla} />
            </div>
            <Link
              href="https://filezilla-project.org/"
              id="filezilla-link"
              isNewTab
              label={(
                <div className="column-icon-container">
                  <FilezillaIcon />
                  <Typography variant="xs" font="semibold" label={Translation.download} />
                </div>
              )}
            />
          </div>
        )}

        <div className="column">
          <div className="column-title-container">
            <Typography variant="s" font="semibold" label={Translation.ftp_guide} />
          </div>
          <div className="column-subtitle-container">
            <Typography lineHeight={PX_TO_REM['21']} variant="xs" label={Translation.below_you_will_find} />
          </div>
          <Link
            href={ftpGuideLinks}
            id="filezilla-guide-link"
            isNewTab
            label={(
              <div className="column-icon-container">
                <img src="/image/filezilla_guide.png" alt="Filezilla guide" />
                <Typography variant="xs" font="semibold" label={Translation.download} />
              </div>
            )}
          />
        </div>
      </div>

      <div className="bottom-section">
        <TableTopInfo
          title={Translation.ftp_folders}
          chipText={`${folderCount} ${folderCount > 1 ? Translation.folders : Translation.folder}`}
          description={Translation.here_you_can_view}
        />

        {folders && (
          <Table
            data={transformData()}
            count={folderCount}
            columns={tableColumns}
            onPageChange={onPageChange}
            rowsPerPageValue={rowsPerPage}
            onRowsPerPageChange={onRowsPerPageChange}
            cellHeight="small"
            scrollable={false}
            emptyDataText="there_are_no_folders"
          />
        )}
      </div>

      <ResetPasswordModal
        shownPassword={password}
        open={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        modalType={modalType}
        setModalType={setModalType}
      />
    </div>
  )
}

export default TableView
