import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@mui/material/Tooltip'

import { Translation } from '../../../helpers/Translation'
import { localizeDate } from '../../../helpers/Utils'
import {
  DEFAULT_PAGE,
  NOT_SET_PLACEHOLDER,
  PX_TO_REM,
} from '../../../helpers/Constants'

import Typography from '../../../components/Typography'
import Link from '../../../components/Link'

import ProfilePicture from '../../../layouts/Common/ProfilePicture'
import DotAnimation from '../../../layouts/Common/DotAnimation'

import ContentAccordion from '../../../layouts/Common/ContentAccordion'

import ChecboxTooltip from './ChecboxTooltip'

import { ReactComponent as LayersSvg } from '../../../svg/layers.svg'
import { ReactComponent as SettingsSvg } from '../../../svg/settings.svg'
import { ReactComponent as InfoSvg } from '../../../svg/info.svg'
import { ReactComponent as NewTabSvg } from '../../../svg/new_tab.svg'

import './index.scss'

const TableContent = ({ data }) => {
  // eslint-disable-next-line
  const [activeTab, setActiveTab] = useState('settings')

  const formatData = [
    {
      label: Translation.general,
      type: 'info',
      children: [
        {
          label: Translation.trigger,
          value: data?.template?.trigger,
          render: 'text',
        },
        {
          label: Translation.workflow_pricing,
          value: data?.template?.pricing,
          render: 'text',
        },
        {
          label: Translation.processing_days,
          value: data?.template?.processing_days,
          render: 'text',
        },
        {
          label: Translation.processing_times,
          value: data?.template?.processing_times,
          render: 'text',
        },
      ],
    },
    {
      label: Translation.delivery,
      type: 'info',
      children: [
        {
          label: Translation.client_approval_required,
          value: data?.template?.client_approval_required,
          render: 'checkbox',
          tooltip: Translation.workflow_approval_required_tooltip_text,
        },
        {
          label: Translation.partial_delivery,
          value: data?.template?.partial_delivery,
          render: 'checkbox',
          tooltip: Translation.workflow_partial_delivery_tooltip_text,
        },
        {
          label: Translation.delivery_via_link_list,
          value: data?.template?.delivery_via_link_list,
          render: 'checkbox',
        },
        {
          label: Translation.one_order_per_folder,
          value: data?.template?.one_order_per_folder,
          render: 'checkbox',
        },
      ],
    },
    {
      label: Translation.optional,
      type: 'info',
      children: [
        {
          label: Translation.skip_previously_processed_filenames,
          value: data?.template?.skip_previously_processed_filenames,
          render: 'checkbox',
          tooltip: Translation.workflow_previously_processed_filenames_tooltip_text,
        },
        {
          label: Translation.is_prioritised,
          value: data?.template?.is_prioritised,
          render: 'checkbox',
        },
        {
          label: `${Translation.flag_files}: ${Translation.flag_files_input}`,
          value: data?.template?.flag_files_input || NOT_SET_PLACEHOLDER,
          render: 'text',
        },
        {
          label: `${Translation.flag_files}: ${Translation.flag_files_output}`,
          value: data?.template?.flag_files_output || NOT_SET_PLACEHOLDER,
          render: 'text',
        },
      ],
    },
    {
      label: Translation.workflow_invoice,
      type: 'info',
      children: [
        {
          label: Translation.cost_center_separate_invoice,
          value: data?.template?.cost_center || NOT_SET_PLACEHOLDER,
          tooltip: Translation.workflow_table_invoice_tooltip_text,
          render: 'text',
        },
      ],
    },
    {
      label: Translation.user_assigned_to_workflow,
      type: 'user',
      tooltip: Translation.workflow_table_content_user_tooltip_text,
      value: data?.template_user_accounts,
    },
    {
      label: Translation.filefront_users,
      type: 'user',
      tooltip: Translation.workflow_table_content_filefront_user_tooltip_text,
      value: data?.template_user_accounts_public,
    },
  ]

  return (
    <div className="table-content">
      {
        Object.keys(data).length > 0 ? (
          <>
            <div className="table-content-tabs">
              <button
                type="button"
                className={`table-content-tab ${activeTab === 'settings' ? 'active' : ''}`}
                onClick={() => setActiveTab('settings')}
              >
                <SettingsSvg />
                <Typography
                  variant="subtitle"
                  label={Translation.settings}
                  font={activeTab === 'settings' ? 'semibold' : ''}
                  lineHeight={PX_TO_REM['26']}
                />
              </button>
              {
                data?.template_actions?.length > 0 && (
                  <button
                    type="button"
                    className={`table-content-tab ${activeTab === 'formats' ? 'active' : ''}`}
                    onClick={() => setActiveTab('formats')}
                  >
                    <LayersSvg />
                    <Typography
                      variant="subtitle"
                      label={Translation.formats}
                      font={activeTab === 'formats' ? 'semibold' : ''}
                      lineHeight={PX_TO_REM['26']}
                    />
                  </button>
                )
              }
            </div>
            <div className="table-content-settings" style={{ display: activeTab === 'settings' ? 'grid' : 'none' }}>
              {
                formatData.map((item, index) => (
                  <Fragment key={item.label}>
                    <div
                      className="separate-line-wrapper"
                      // eslint-disable-next-line max-len
                      style={{ display: index !== 0 && item?.type === 'info' && item?.children?.length > 0 ? 'block' : 'none' }}
                    >
                      <div className="separate-line" />
                    </div>
                    <div className="detail-row">
                      <div
                        className="detail-row-title"
                        // eslint-disable-next-line no-nested-ternary
                        style={{ display: item.type === 'user' ? (item?.value?.length ? 'flex' : 'none') : 'flex' }}
                      >
                        <Typography variant="xs" font="semibold" label={item.label} />
                        {
                          item?.tooltip && (
                            <Tooltip
                              className="info-tooltip"
                              PopperProps={{ disablePortal: true }}
                              title={item?.tooltip}
                              placement="top-start"
                              arrow
                            >
                              <InfoSvg />
                            </Tooltip>
                          )
                        }
                      </div>
                      <div className="detail-row-content">
                        {
                          item?.type === 'info' && (
                            item?.children?.map((child) => (
                              <div key={child.label}>
                                {
                                  child.render === 'text' && (
                                    <div className="detail-row-content-item item-text">
                                      <Typography variant="xs" label={`${child.label}:`} />
                                      <div className="item-text-badge">
                                        <Typography variant="xs" label={child.value} />
                                      </div>
                                      {
                                        child?.tooltip && (
                                          <Tooltip
                                            className="info-tooltip"
                                            PopperProps={{ disablePortal: true }}
                                            title={child?.tooltip}
                                            placement="top-start"
                                            arrow
                                          >
                                            <InfoSvg />
                                          </Tooltip>
                                        )
                                      }
                                    </div>
                                  )
                                }
                                {
                                  child.render === 'checkbox' && (
                                    <div
                                      className={`detail-row-content-item item-checkbox ${!child.value ? 'disabled' : ''}`}
                                    >
                                      <ChecboxTooltip checked={!!child.value} />
                                      <Typography variant="xs" label={child.label} />
                                      {
                                        child?.tooltip && (
                                          <Tooltip
                                            className="info-tooltip"
                                            PopperProps={{ disablePortal: true }}
                                            title={child?.tooltip}
                                            placement="top-start"
                                            arrow
                                          >
                                            <InfoSvg />
                                          </Tooltip>
                                        )
                                      }
                                    </div>
                                  )
                                }
                              </div>
                            ))
                          )
                        }
                        {
                          item?.type === 'user' && (
                            item?.value?.map((user) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <div key={`${user.id}`} className="detail-row-content-item user-profile">
                                <ProfilePicture
                                  avatarUrl={user.avatar_url}
                                  firstname={user.firstname}
                                  lastname={user.lastname}
                                  fontSize={PX_TO_REM['12']}
                                  fontWeight="semibold"
                                  bgColor={user.avatar_bg}
                                  width={PX_TO_REM['32']}
                                  height={PX_TO_REM['32']}
                                  showImage={!!user.has_avatar}
                                />
                                <Typography key={user.id} variant="body1" label={`${user.firstname} ${user.lastname}`} />
                              </div>
                            ))
                          )
                        }
                      </div>
                    </div>
                  </Fragment>
                ))
              }
            </div>
            <div style={{ display: activeTab === 'formats' ? 'block' : 'none' }}>
              <div>
                {
                  data?.template_actions?.length > 0 && (
                    data?.template_actions?.map((item, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <ContentAccordion key={item.label} data={item} index={index} />
                    ))
                  )
                }
              </div>
              <div className="content-bottom">
                <div className="content-bottom-viewText">
                  <NewTabSvg />
                  <Link
                    variant="xs"
                    label={Translation.view_all_orders_related_to_workflow}
                    href={`${DEFAULT_PAGE}?workflow=${data?.template?.id}`}
                    isNewTab
                    underline
                  />
                </div>
                <Typography
                  variant="xs"
                  label={`${Translation.changed_on}: ${localizeDate(data?.template?.timestamp)}`}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="table-animation">
            <DotAnimation />
          </div>
        )
      }
    </div>
  )
}

export default TableContent

TableContent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

TableContent.defaultProps = {
  data: {},
}
