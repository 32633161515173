import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import PropTypes from 'prop-types'
import { Tooltip } from '@mui/material'

import { Translation } from '../../../helpers/Translation'
import { isValidValue, removeInputError } from '../../../helpers/Utils'
import {
  FIELDS_MIN_LENGTH,
  FIELDS_PATTERNS,
  FTP_MODAL_TYPE,
  FTP_STATUS,
  KEYS,
  OK_PASSWORD_SCORE,
  PX_TO_REM,
} from '../../../helpers/Constants'

import Modal from '../../../components/Modal'
import Typography from '../../../components/Typography'
import Button from '../../../components/Button'
import Input from '../../../components/Input'

import { ReactComponent as CopyIcon } from '../../../svg/copy.svg'
import { ReactComponent as ShowPasswordIcon } from '../../../svg/show_password.svg'
import { ReactComponent as CheckIcon } from '../../../svg/check_curved.svg'

import PasswordStrengthBar from '../../../layouts/Common/PasswordStrengthBar'

import './index.scss'

const ResetPasswordModal = ({
  shownPassword,
  open,
  closeModal,
  modalType,
  setModalType,
}) => {
  const updateSnackbarState = useStoreActions((actions) => actions.global.updateSnackbarState)
  const ftpInfo = useStoreState((state) => state.ftp.ftpInfo)
  const resetPassword = useStoreActions((state) => state.ftp.resetPassword)
  const requestDone = useStoreActions((state) => state.ftp.requestDone)
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [isFormErrors, setIsFormErrors] = useState({})
  const [passwordScore, setPasswordScore] = useState(0)
  const [formErrors] = useState({
    password: Translation.must_be_at_least_chars_long,
    passwordRepeat: Translation.passwords_do_not_match,
  })
  const [isPasswordCopied, setIsPasswordCopied] = useState(false)

  const showOrCopyPassword = () => {
    if (isPasswordVisible) {
      navigator?.clipboard?.writeText(shownPassword).then(() => { })
      setIsPasswordCopied(true)
      setTimeout(() => {
        setIsPasswordCopied(false)
      }, 2000)
    } else {
      requestDone()
      setIsPasswordVisible(true)
    }
  }

  const onLetUsGeneratePassword = () => {
    resetPassword({
      password: ftpInfo.ftp_password_generated,
      'password-repeat': ftpInfo.ftp_password_generated,
    })
    setIsPasswordVisible(true)
    setModalType(FTP_MODAL_TYPE.show)
  }

  const isFirstFormValid = () => {
    let isValid = true
    const isFormErrorsUpdated = {}

    if (!isValidValue(password, FIELDS_PATTERNS.simplePassword) || passwordScore < OK_PASSWORD_SCORE) {
      isValid = false
      isFormErrorsUpdated.password = !isValid
    }

    if (!passwordRepeat || password !== passwordRepeat) {
      isValid = false
      isFormErrorsUpdated.passwordRepeat = !isValid
    }

    if (!isValid) setIsFormErrors(isFormErrorsUpdated)

    return isValid
  }

  const onClickOutside = (e) => {
    if (e.target?.outerHTML?.includes('modal--wrap')) {
      closeModal()
    }
  }

  const onBack = () => {
    if (ftpInfo.user_ftp_request_status === FTP_STATUS.requestGranted
        || ftpInfo.user_ftp_request_status === FTP_STATUS.requestGrantedNewUser) {
      closeModal()
      return
    }
    setModalType(FTP_MODAL_TYPE.question)
    setIsFormErrors({})
  }

  const onOK = async () => {
    if (modalType === FTP_MODAL_TYPE.resetPassword && isFirstFormValid()) {
      await resetPassword({
        password,
        'password-repeat': passwordRepeat,
      })
      updateSnackbarState({
        isOpen: true,
        message: Translation.ftp_password_updated,
        variant: 'success',
      })
      closeModal()
    } else if (modalType === FTP_MODAL_TYPE.show) {
      closeModal()
    }
  }

  const handlePasswordChange = (e) => {
    const input = e.currentTarget
    setPassword(input.value.trim())
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const isRepeatPasswordStatusValid = () => !(!passwordRepeat || password !== passwordRepeat)

  const handlePasswordRepeatChange = (e) => {
    const input = e.currentTarget
    const inputValue = input.value.trim()

    setPasswordRepeat(inputValue)

    if (password === inputValue) {
      setIsFormErrors({ ...isFormErrors, passwordRepeat: false })
    }
  }

  const handlePasswordRepeatKeyUp = (e) => {
    if (e.key === KEYS.enter && isFirstFormValid()) {
      isRepeatPasswordStatusValid()
      onOK().then(() => { })
    }
  }

  useEffect(() => {
    setPassword('')
    setPasswordRepeat('')
    setIsPasswordVisible(false)
    setIsFormErrors({})
  }, [open])

  return (
    <Modal
      hasCloseIcon
      isShown={open}
      onClickCloseIcon={() => closeModal()}
      onClickEscClose={() => closeModal()}
      onClickOutside={onClickOutside}
    >
      <div className="reset-password-modal">
        <div className="reset-title-container">
          {(modalType === FTP_MODAL_TYPE.question || modalType === FTP_MODAL_TYPE.resetPassword) && (
            <Typography variant="h6" lineHeight={PX_TO_REM['25']} font="semibold" label={Translation.reset_your_ftp_password} />
          )}
          {modalType === FTP_MODAL_TYPE.show && (
            <Typography variant="h6" lineHeight={PX_TO_REM['25']} font="semibold" label={Translation.copy_your_ftp_password} />
          )}
        </div>
        <div className="reset-description-container">
          {modalType === FTP_MODAL_TYPE.question && (
            <Typography
              variant="xs-narrow"
              label={Translation.choose_generated_password}
            />
          )}
          {modalType === FTP_MODAL_TYPE.resetPassword && (
            <Typography
              variant="xs-narrow"
              label={Translation.please_create_your_own_password}
            />
          )}
          {modalType === FTP_MODAL_TYPE.show && (
            <Typography
              variant="xs-narrow"
              label={Translation.copy_the_password_below}
            />
          )}
        </div>
        {modalType === FTP_MODAL_TYPE.question && (
          <>
            <div className="reset-button-container">
              <Button
                size="s"
                type="contained"
                onClick={onLetUsGeneratePassword}
                label={Translation.let_us_generate_your_password}
              />
              <Typography variant="xs" label={Translation.or} />
              <Button
                size="s"
                type="contained"
                onClick={() => setModalType(FTP_MODAL_TYPE.resetPassword)}
                label={Translation.create_your_own_password}
              />
            </div>
            <div className="please-note">
              <Typography variant="xs" containsHtml label={Translation.once_password_changed} />
            </div>
          </>
        )}
        {modalType === FTP_MODAL_TYPE.resetPassword && (
          <div className="reset-password-container">
            <div className="form-control">
              <Input
                type="password"
                name="password"
                label={`${Translation.password}*`}
                isError={isFormErrors.password}
                minLength={FIELDS_MIN_LENGTH.ftp_password}
                pattern={FIELDS_PATTERNS.simplePassword}
                placeholder={Translation.create_password}
                value={password}
                onChange={handlePasswordChange}
                disableAutocomplete
              />
              {
                (password || isFormErrors.password) ? (
                  <PasswordStrengthBar
                    password={password}
                    minLength={FIELDS_MIN_LENGTH.password}
                    setPasswordScore={(score) => setPasswordScore(score)}
                  />
                ) : null
              }
            </div>
            <div className="form-control">
              <Input
                type="password"
                name="passwordRepeat"
                label={`${Translation.repeat_password}*`}
                error={formErrors.passwordRepeat}
                isError={isFormErrors.passwordRepeat}
                minLength={FIELDS_MIN_LENGTH.password}
                pattern={FIELDS_PATTERNS.simplePassword}
                placeholder={Translation.repeat_a_password}
                value={passwordRepeat}
                onChange={handlePasswordRepeatChange}
                onKeyUp={handlePasswordRepeatKeyUp}
                {...(passwordRepeat && (passwordRepeat === password)) && { endAdornment: <CheckIcon /> }}
                disableAutocomplete
              />
            </div>
          </div>
        )}
        {modalType === FTP_MODAL_TYPE.show && (
          <div className="copy-container">
            <div className="input-view">
              <Typography variant="xs" label={isPasswordVisible ? shownPassword : '********'} />
              <Button
                onClick={showOrCopyPassword}
                label={
                  isPasswordVisible
                    ? (
                      <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        open={isPasswordCopied}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={Translation.copied}
                      >
                        <CopyIcon className="copy-icon" />
                      </Tooltip>
                    )
                    : <ShowPasswordIcon />
                }
              />
            </div>
          </div>
        )}
        {(modalType === FTP_MODAL_TYPE.resetPassword || modalType === FTP_MODAL_TYPE.show) && (
          <div className="modal-footer">
            <Button size="s" onClick={onBack} type="contained" label={Translation.back} />
            <Button size="s" onClick={onOK} label={Translation.ok} />
          </div>
        )}
      </div>
    </Modal>
  )
}

ResetPasswordModal.propTypes = {
  shownPassword: PropTypes.string,
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  modalType: PropTypes.number,
  setModalType: PropTypes.func,
}

ResetPasswordModal.defaultProps = {
  shownPassword: '',
  open: false,
  closeModal: () => { },
  modalType: FTP_MODAL_TYPE.question,
  setModalType: () => { },
}

export default ResetPasswordModal
