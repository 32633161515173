import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStoreActions } from 'easy-peasy'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'

import { Translation } from '../../../../helpers/Translation'

import Select from '../../../../components/Select'
import Input from '../../../../components/Input'

import ColumnSwitch from '../../Common/ColumnSwitch'
import SectionTop from '../../Common/SectionTop'

import { ReactComponent as MarginSvg } from '../../../../svg/margin.svg'

import './index.scss'

const Resize = ({ handlePropertyId, orderFormatActions, disableAll }) => {
  const updateLocaleFormatActions = useStoreActions((actions) => actions.order.updateLocaleFormatActions)
  const [inputErrorId, setInputErrorId] = useState(null)
  const [isResolutionSelectedCustom, setIsResolutionSelectedCustom] = useState(false)

  const resolutionValues = {
    property_resolution_72: '72 dpi (Web)',
    property_resolution_300: `300 dpi (${Translation.print})`,
    property_resolution_custom: Translation.custom,
  }

  const positioningValues = {
    property_positioning1: Translation.center,
    property_positioning2: Translation.fill_image,
    property_positioning4: Translation.upper_edge,
    property_positioning3: Translation.bottom_edge,
  }

  // keyPress() is deprecated, but keyUp() or keyDown() is not working
  // if you want to delete one letter
  const handleInputOnKeyPress = (e, id) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault()
      setInputErrorId(id)
      setTimeout(() => {
        setInputErrorId(null)
      }, 1000)
    } else {
      setInputErrorId(null)
    }
  }

  return (
    <section className={`new-order-section section-resize ${orderFormatActions?.property?.value ? 'active' : ''}`}>
      <Accordion expanded={!!orderFormatActions?.property?.value} className="accordion">
        <AccordionSummary className="accordion-summary">
          <SectionTop
            showContent={!!orderFormatActions?.property?.value}
            onChange={(id, value) => { handlePropertyId(id, value) }}
            id="property"
            icon={<MarginSvg />}
            defaultChecked={orderFormatActions?.property?.value !== null}
            labelText={orderFormatActions?.property?.label}
            modalText={Translation.resize_modal_text}
            badgeText={orderFormatActions?.property?.price}
            disabled={disableAll}
          />
        </AccordionSummary>
        <AccordionDetails className="accordion-details">
          <div className="section-content">
            <div className="section-content-columns">
              <div className="section-content-column">
                <ColumnSwitch
                  id="property_size"
                  labelText={orderFormatActions?.property_size?.label}
                  modalText={Translation.resize_image_size_modal}
                  badgeText={orderFormatActions?.property_size?.price}
                  defaultChecked={orderFormatActions?.property_size?.value !== null}
                  onChange={(id, value) => handlePropertyId(id, value)}
                  disabled={disableAll}
                />
                <div className="column-content" style={{ display: orderFormatActions.property_size.value ? 'block' : 'none' }}>
                  <div className="column-inputs">
                    <div className="column-input">
                      <Input
                        placeholder={Translation.width}
                        type="number"
                        value={orderFormatActions?.property_size_width?.value || ''}
                        onChange={(e) => {
                          updateLocaleFormatActions({
                            property_size_width:
                              { ...orderFormatActions.property_size_width, value: e.target.value },
                          })
                        }}
                        onBlur={() => handlePropertyId('property_size_width', orderFormatActions?.property_size_width?.value)}
                        onKeyPress={(e) => handleInputOnKeyPress(e, 'property_size_width')}
                        isError={inputErrorId === 'property_size_width'}
                        error={Translation.must_be_an_integer}
                        endAdornment={`${Translation.width} (${Translation.px_short})`}
                        disabled={disableAll}
                      />
                    </div>
                    <div className="column-input">
                      <Input
                        placeholder={Translation.height}
                        type="number"
                        value={orderFormatActions?.property_size_height?.value || ''}
                        onChange={(e) => {
                          updateLocaleFormatActions({
                            property_size_height:
                              { ...orderFormatActions.property_size_height, value: e.target.value },
                          })
                        }}
                        onBlur={() => handlePropertyId('property_size_height', orderFormatActions?.property_size_height?.value)}
                        onKeyPress={(e) => handleInputOnKeyPress(e, 'property_size_height')}
                        isError={inputErrorId === 'property_size_height'}
                        error={Translation.must_be_an_integer}
                        endAdornment={`${Translation.height} (${Translation.px_short})`}
                        disabled={disableAll}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-content-column">
                <ColumnSwitch
                  id="property_resolution"
                  labelText={orderFormatActions?.property_resolution?.label}
                  modalText={Translation.resize_resolution_modal}
                  badgeText={orderFormatActions?.property_resolution?.price}
                  defaultChecked={orderFormatActions?.property_resolution?.value !== null}
                  onChange={(id, value) => handlePropertyId(id, value)}
                  disabled={disableAll}
                />
                <div
                  className="column-content"
                  style={{ display: orderFormatActions?.property_resolution?.value ? 'block' : 'none' }}
                >
                  <div className="column-select">
                    <Select
                      isEmptyOption={false}
                      values={resolutionValues}
                      value={(orderFormatActions?.property_resolution?.value === '1'
                        && orderFormatActions?.property_resolution_selected?.value) || Object.keys(resolutionValues)[0]}
                      onChange={(e) => {
                        handlePropertyId('property_resolution_selected', e.target.value)
                        if (e.target.value === 'property_resolution_custom') {
                          setIsResolutionSelectedCustom(true)
                        }
                      }}
                      itemClass="new-order-select-item"
                      disabled={disableAll}
                    />
                  </div>
                  {orderFormatActions?.property_resolution?.value === '1'
                    && orderFormatActions?.property_resolution_selected?.value === 'property_resolution_custom' && (
                      <div className="column-input">
                        <Input
                          placeholder={`${Translation.resolution} (dpi)`}
                          type="number"
                          value={
                            // TODO errors appear in the console.
                            // But with '' instead null field is not editable after changing resolution select option
                            isResolutionSelectedCustom ? null : (orderFormatActions?.property_resolution_dpi?.value || '')
                          }
                          onChange={(e) => {
                            updateLocaleFormatActions({
                              property_resolution_dpi:
                                { ...orderFormatActions.property_resolution_dpi, value: e.target.value },
                            })
                          }}
                          onBlur={() => {
                            handlePropertyId('property_resolution_dpi', orderFormatActions?.property_resolution_dpi?.value)
                          }}
                          onKeyPress={(e) => handleInputOnKeyPress(e, 'property_resolution_dpi')}
                          isError={inputErrorId === 'property_resolution_dpi'}
                          error={Translation.must_be_an_integer}
                          disabled={disableAll}
                          endAdornment={Translation.dpi_short}
                        />
                      </div>
                  )}
                </div>
              </div>
              <div className="section-content-column">
                <ColumnSwitch
                  id="property_positioning"
                  labelText={orderFormatActions?.property_positioning?.label}
                  modalText={Translation.resize_positioning_modal}
                  badgeText={orderFormatActions?.property_positioning?.price}
                  defaultChecked={orderFormatActions?.property_positioning?.value !== null}
                  onChange={(id, value) => handlePropertyId(id, value)}
                  disabled={disableAll}
                />
                <div
                  className="column-content"
                  style={{ display: orderFormatActions?.property_positioning?.value ? 'block' : 'none' }}
                >
                  <div className="column-select">
                    <Select
                      isEmptyOption={false}
                      values={positioningValues}
                      value={orderFormatActions?.property_positioning_selected?.value || Object.keys(positioningValues)[0]}
                      onChange={(e) => handlePropertyId('property_positioning_selected', e.target.value)}
                      itemClass="new-order-select-item"
                      disabled={disableAll}
                    />
                  </div>
                </div>
              </div>
              <div className="section-content-column">
                <ColumnSwitch
                  id="property_margin"
                  labelText={orderFormatActions?.property_margin?.label}
                  modalText={Translation.resize_margin_modal}
                  badgeText={orderFormatActions?.property_margin?.price}
                  defaultChecked={orderFormatActions?.property_margin?.value !== null}
                  onChange={(id, value) => handlePropertyId(id, value)}
                  disabled={disableAll}
                />
                <div
                  className="column-content"
                  style={{ display: orderFormatActions.property_margin.value ? 'block' : 'none' }}
                >
                  <div className="column-inputs">
                    <div className="column-input">
                      <Input
                        placeholder={Translation.top}
                        type="number"
                        value={orderFormatActions?.property_margin_top?.value || ''}
                        onChange={(e) => {
                          updateLocaleFormatActions({
                            property_margin_top:
                              { ...orderFormatActions.property_margin_top, value: e.target.value },
                          })
                        }}
                        onBlur={() => handlePropertyId('property_margin_top', orderFormatActions?.property_margin_top?.value)}
                        onKeyPress={(e) => handleInputOnKeyPress(e, 'property_margin_top')}
                        isError={inputErrorId === 'property_margin_top'}
                        error={Translation.must_be_an_integer}
                        endAdornment={`${Translation.top} (${Translation.px_short})`}
                        disabled={disableAll}
                      />
                    </div>
                    <div className="column-input">
                      <Input
                        placeholder={Translation.bottom}
                        type="number"
                        value={orderFormatActions?.property_margin_bottom?.value || ''}
                        onChange={(e) => {
                          updateLocaleFormatActions({
                            property_margin_bottom:
                              { ...orderFormatActions.property_margin_bottom, value: e.target.value },
                          })
                        }}
                        onBlur={() => {
                          handlePropertyId('property_margin_bottom', orderFormatActions?.property_margin_bottom?.value)
                        }}
                        onKeyPress={(e) => handleInputOnKeyPress(e, 'property_margin_bottom')}
                        isError={inputErrorId === 'property_margin_bottom'}
                        error={Translation.must_be_an_integer}
                        endAdornment={`${Translation.bottom} (${Translation.px_short})`}
                        disabled={disableAll}
                      />
                    </div>
                    <div className="column-input">
                      <Input
                        placeholder={Translation.left}
                        type="number"
                        value={orderFormatActions?.property_margin_left?.value || ''}
                        onChange={(e) => {
                          updateLocaleFormatActions({
                            property_margin_left:
                              { ...orderFormatActions.property_margin_left, value: e.target.value },
                          })
                        }}
                        onBlur={() => {
                          handlePropertyId('property_margin_left', orderFormatActions?.property_margin_left?.value)
                        }}
                        onKeyPress={(e) => handleInputOnKeyPress(e, 'property_margin_left')}
                        isError={inputErrorId === 'property_margin_left'}
                        error={Translation.must_be_an_integer}
                        endAdornment={`${Translation.left} (${Translation.px_short})`}
                        disabled={disableAll}
                      />
                    </div>
                    <div className="column-input">
                      <Input
                        placeholder={Translation.right}
                        type="number"
                        value={orderFormatActions?.property_margin_right?.value || ''}
                        onChange={(e) => {
                          updateLocaleFormatActions({
                            property_margin_right:
                              { ...orderFormatActions.property_margin_right, value: e.target.value },
                          })
                        }}
                        onBlur={() => {
                          handlePropertyId('property_margin_right', orderFormatActions?.property_margin_right?.value)
                        }}
                        onKeyPress={(e) => handleInputOnKeyPress(e, 'property_margin_right')}
                        isError={inputErrorId === 'property_margin_right'}
                        error={Translation.must_be_an_integer}
                        endAdornment={`${Translation.right} (${Translation.px_short})`}
                        disabled={disableAll}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </section>
  )
}

Resize.propTypes = {
  handlePropertyId: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  orderFormatActions: PropTypes.objectOf(PropTypes.any),
  disableAll: PropTypes.bool,
}

Resize.defaultProps = {
  orderFormatActions: {},
  disableAll: false,
}

export default Resize
